import _ from "lodash";
import React from "react";
// import { useSelector } from "react-redux";
import {
  CALLBACK,
  LOGOUT,
  LOGOUT_CALLBACK,
  SILENT_RENEW,
} from "components/auth";
import {
  Route,
  Routes,
  BrowserRouter,
} from "react-router-dom";

import route from "./route";

import Custom404 from "pages/404";

import { BasicLayout } from "components/Layout";

const PerjanjianRoutes = () => {
  // const { token } = useSelector((state) => state.auth);
  return (
    <BrowserRouter>
      <Routes>
        <Route exact={true} path="/signin-oidc" element={<CALLBACK />} />
        <Route exact={true} path="/logout" element={<LOGOUT />} />
        <Route exact={true} path="/logout/callback" element={<LOGOUT_CALLBACK />} />
        <Route exact={true} path="/silentrenew" element={<SILENT_RENEW />} />

        <Route element={<BasicLayout />}>
          {_.map(_.filter(route, { routeType: "private" }), (val, key) => (
            <Route
              exact
              key={key}
              path={val.path}
              element={<val.element />}
            />
          ))}
        </Route>

        <Route path="*" element={<Custom404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default PerjanjianRoutes;