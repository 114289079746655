import classNames from "classnames";
import {
  Breadcrumbs,
  Button,
  CustomSelect,
  SearchInput,
  Table,
  TitleText,
} from "components";
import { map } from "lodash";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { BiPencil, BiPlus } from "react-icons/bi";
import { useNavigate } from "react-router";
import { removeLocalStorage } from "utils";

const links = [
  {
    label: "Penyusunan Perjanjian",
    path: "#",
  },
  {
    label: "Perjanjian Adendum",
  },
];

const categories = [
  {
    label: "Perhutanan sosial",
    value: "Perhutanan sosial",
  },
  {
    label: "Non Perhutanan Sosial",
    value: "Non Perhutanan Sosial",
  },
];

const tabs = [
  {
    label: "Daftar Agenda Penandatanganan Adendum/Perjanjian",
  },
  {
    label: "Daftar Debitur Dalam Proses Penandatanganan Adendum/Perjanjian",
  },
];

const agendaData = {
  status: 200,
  message: "OK",
  result: [
    {
      _id: "657c745c293ce43803d3e55c",
      signature_date: new Date(),
      no_inv_impl: 'ST-TES-PEKALONGAN',
      total_debtors: 5,
      notary_name: 'Aji BS',
      signatory_signature: 'Pegawai 192',
      signatory_position: 'petugas lapangan',
      delegation_decree_no: 'KEP-1-DELEGASI',
      date_of_delegation: new Date(),
    },
    {
      _id: "657c745c293ce43803d3e55c",
      signature_date: new Date(),
      no_inv_impl: 'ST-TES-PEKALONGAN',
      total_debtors: 5,
      notary_name: 'Aji BS',
      signatory_signature: 'Pegawai 192',
      signatory_position: 'petugas lapangan',
      delegation_decree_no: 'KEP-1-DELEGASI',
      date_of_delegation: new Date(),
    },
    {
      _id: "657c745c293ce43803d3e55c",
      signature_date: new Date(),
      no_inv_impl: 'ST-TES-PEKALONGAN',
      total_debtors: 5,
      notary_name: 'Aji BS',
      signatory_signature: 'Pegawai 192',
      signatory_position: 'petugas lapangan',
      delegation_decree_no: 'KEP-1-DELEGASI',
      date_of_delegation: new Date(),
    },
    {
      _id: "657c745c293ce43803d3e55c",
      signature_date: new Date(),
      no_inv_impl: 'ST-TES-PEKALONGAN',
      total_debtors: 5,
      notary_name: 'Aji BS',
      signatory_signature: 'Pegawai 192',
      signatory_position: 'petugas lapangan',
      delegation_decree_no: 'KEP-1-DELEGASI',
      date_of_delegation: new Date(),
    },
  ],
  paginator: {
    itemCount: 4,
    limit: 10,
    pageCount: 1,
    page: 1,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
};

const debtorData = {
  status: 200,
  message: "OK",
  result: [
    {
      _id: "657c745c293ce43803d3e55c",
      signature_date: new Date(),
      no_inv_impl: 'ST-TES-PEKALONGAN',
      date_application: new Date(),
      no_application_letter: '03.008 / KTHJMS / X / 2022',
      debtor_name: 'Anggia',
      group_name: 'Kelompok Tani'
    },
    {
      _id: "657c745c293ce43803d3e55c",
      signature_date: new Date(),
      no_inv_impl: 'ST-TES-PEKALONGAN',
      date_application: new Date(),
      no_application_letter: '03.008 / KTHJMS / X / 2022',
      debtor_name: 'Yuda',
      group_name: 'Kelompok Tani'
    },
    {
      _id: "657c745c293ce43803d3e55c",
      signature_date: new Date(),
      no_inv_impl: 'ST-TES-PEKALONGAN',
      date_application: new Date(),
      no_application_letter: '03.008 / KTHJMS / X / 2022',
      debtor_name: 'Tedi',
      group_name: 'Kelompok Tani'
    },
    {
      _id: "657c745c293ce43803d3e55c",
      signature_date: new Date(),
      no_inv_impl: 'ST-TES-PEKALONGAN',
      date_application: new Date(),
      no_application_letter: '03.008 / KTHJMS / X / 2022',
      debtor_name: 'Anggia',
      group_name: 'Kelompok Tani'
    },
  ],
  paginator: {
    itemCount: 4,
    limit: 10,
    pageCount: 1,
    page: 1,
    hasPrevPage: false,
    hasNextPage: false,
    prevPage: null,
    nextPage: null,
  },
};

const Adendum = () => {
  const navigate = useNavigate();

  const [tabActive, setTabActive] = useState(0);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    // sort: "desc",
    // sortBy: "updated_at",
    search: "",
    year: "",
    category: "",
  });

  const listYear = useMemo(() => {
    let currentYear = new Date().getFullYear();
    let years = [];
    let startYear = 2000;
    while (startYear <= currentYear) {
      years.push(startYear++);
    }

    years.reverse();

    return years.map((year) => ({ value: year, label: year }));
  }, []);

  const handleNavigateForm = useCallback(() => {
    switch (tabActive) {
      case 0:
        navigate("/agreement-direct-distribution/individual-within-group/form");
        break;
      case 1:
        navigate("/agreement-direct-distribution/business-entity/form");
        break;
      default:
    }
  }, [tabActive, navigate]);

  const handleNavigateUpdate = useCallback(
    (id) => {
      removeLocalStorage("form");
      removeLocalStorage("template");
      switch (tabActive) {
        case 0:
          navigate(
            `/agreement-direct-distribution/${id}/individual-within-group/form`
          );
          break;
        case 1:
          navigate(`/agreement-direct-distribution/${id}/business-entity/form`);
          break;
        default:
      }
    },
    [tabActive, navigate]
  );

  const agendaTableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        columnClassName: "w-[50px]",
        width: 50,
        render: (value, data, index) => {
          const no =
            parseInt(agendaData.paginator.page) *
              parseInt(agendaData.paginator.limit) -
            parseInt(agendaData.paginator.limit) +
            index +
            1;
          // 1;

          return no;
        },
      },
      {
        id: "signature_date",
        title: "Tanggal Penandatanganan",
        dataIndex: "signature_date",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.signature_date
              ? moment(data?.signature_date).format("DD/MM/YYYY")
              : "-"}
          </div>
        ),
      },
      {
        id: "no_inv_impl",
        title: "No Undangan Pelaksanaan",
        dataIndex: "no_inv_impl",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data.no_inv_impl}
          </div>
        ),
      },
      {
        id: "total_debtors",
        title: "Jumlah Debitur",
        dataIndex: "total_debtors",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data.total_debtors ?? "-"}
          </div>
        ),
      },
      {
        id: "notary_name",
        title: "Nama Notaris",
        dataIndex: "notary_name",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data.notary_name ?? "-"}
          </div>
        ),
      },
      {
        id: "signatory_signature",
        title: "Nama Jabatan Penandatanganan",
        dataIndex: "signatory_signature",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data.signatory_signature}
          </div>
        ),
      },
      {
        id: "signatory_position",
        title: "Jabatan Penandatanganan",
        dataIndex: "signatory_position",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data.signatory_position}
          </div>
        ),
      },
      {
        id: "delegation_decree_no",
        title: "No Kep Delegasi",
        dataIndex: "delegation_decree_no",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis max-w-[540px] w-max">
            {data.delegation_decree_no}
          </div>
        ),
      },
      {
        id: "date_of_delegation",
        title: "Tanggal Kep Delegasi",
        dataIndex: "date_of_delegation",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.date_of_delegation
              ? moment(data?.date_of_delegation).format("DD/MM/YYYY")
              : "-"}
          </div>
        ),
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "actions",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        columnClassName: "text-center",
        fixed: "right",
        render: (value, data, index) => (
          <div className="flex gap-2">
            {/* <FiEye className="text-gray-600 cursor-pointer flex-1" size={20} /> */}
            <BiPencil
              className={`text-gray-600 cursor-pointer flex-1`}
              size={20}
              onClick={() => handleNavigateUpdate(data?.agreement_id)}
            />
          </div>
        ),
      },
    ];
  }, [handleNavigateUpdate]);

  const debiturTableColumns = useMemo(() => {
    return [
      {
        id: "no",
        title: "No",
        dataIndex: "no",
        columnClassName: "w-[50px]",
        width: 50,
        render: (value, data, index) => {
          const no =
            parseInt(debtorData.paginator.page) *
              parseInt(debtorData.paginator.limit) -
            parseInt(debtorData.paginator.limit) +
            index +
            1;

          return no;
        },
      },
      {
        id: "signature_date",
        title: "Tanggal Penandatanganan",
        dataIndex: "signature_date",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.signature_date
              ? moment(data?.signature_date).format("DD/MM/YYYY")
              : "-"}
          </div>
        ),
      },
      {
        id: "no_inv_impl",
        title: "No Undangan Pelaksanaan",
        dataIndex: "no_inv_impl",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data.no_inv_impl}
          </div>
        ),
      },
      {
        id: "date_application",
        title: "Tanggal Surat Permohonan",
        dataIndex: "date_application",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.date_application
              ? moment(data?.date_application).format("DD/MM/YYYY")
              : "-"}
          </div>
        ),
      },
      {
        id: "no_application_letter",
        title: "No Surat Permohonan",
        dataIndex: "no_application_letter",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.no_application_letter ?? "-"}
          </div>
        ),
      },
      {
        id: "debtor_name",
        title: "Nama Debitur",
        dataIndex: "debtor_name",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.debtor_name ?? "-"}
          </div>
        ),
      },
      {
        id: "group_name",
        title: "Nama Lembaga Kelompok",
        dataIndex: "group_name",
        width: 540,
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        render: (value, data, index) => (
          <div className="overflow-hidden text-ellipsis w-full max-w-[540px]">
            {data?.group_name ?? "-"}
          </div>
        ),
      },
      {
        id: "actions",
        title: "Aksi",
        dataIndex: "actions",
        className: "overflow-hidden text-ellipsis",
        sortable: true,
        columnClassName: "text-center",
        fixed: "right",
        render: (value, data, index) => (
          <div className="flex gap-2">
            {/* <FiEye className="text-gray-600 cursor-pointer flex-1" size={20} /> */}
            <BiPencil
              className={`text-gray-600 cursor-pointer flex-1`}
              size={20}
              onClick={() => handleNavigateUpdate(data.agreement_id)}
            />
          </div>
        ),
      },
    ];
  }, [handleNavigateUpdate]);

  const renderColumn = useCallback(() => {
    switch (tabActive) {
      case 0:
        return agendaTableColumns;
      case 1:
        return debiturTableColumns;
      default:
    }
  }, [tabActive, agendaTableColumns, debiturTableColumns]);

  const renderDataSource = useCallback(() => {
    switch (tabActive) {
      case 0:
        return agendaData?.result;
      case 1:
        return debtorData ? debtorData?.result : [];
      default:
    }
  }, [tabActive]);

  const renderPaginator = useCallback(() => {
    switch (tabActive) {
      case 0:
        return agendaData.paginator;
      case 1:
        return debtorData?.paginator;
      default:
    }
  }, [tabActive]);

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumbs items={links} />

      <div className="md:flex block items-center justify-between">
        <TitleText className="flex-1">Daftar Adendum/Perjanjian</TitleText>
        <Button
          className="bg-primary-700 hover:bg-primary-600 text-white"
          startIcon={<BiPlus />}
          onClick={() => navigate('form')}
        >
          Buat Rencana Pelaksanaan Adendum
        </Button>
      </div>

      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="flex items-center space-x-4">
            <SearchInput
              placeholder="Cari"
              onChange={(e) => {
                setFilters((prev) => ({
                  ...prev,
                  search: e.target.value,
                }));
              }}
            />
            <div className="flex-1">
              <CustomSelect
                placeholder="Status"
                optionsData={[]}
                isClearable
                onChange={(e) => {
                  setFilters((prev) => ({ ...prev, year: e?.value ?? "" }));
                }}
              />
            </div>
            <div className="flex-1">
              <CustomSelect
                placeholder="Tahun"
                optionsData={listYear}
                isClearable
                onChange={(e) => {
                  setFilters((prev) => ({ ...prev, year: e?.value ?? "" }));
                }}
              />
            </div>
            <div className="flex-1">
              <CustomSelect
                placeholder="Karegori"
                optionsData={categories}
                isClearable
                onChange={(e) => {
                  setFilters((prev) => ({ ...prev, category: e?.value ?? "" }));
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="card w-full bg-white shadow-sm rounded-xl border-gray-200 border-solid border-[1px]">
        <div className="card-body p-3">
          <div className="space-y-4">
            <div className="tabs">
              {map(tabs, (tab, key) => (
                <span
                  key={key}
                  role="tab"
                  tabIndex={tabActive === key ? 0 : -1}
                  onClick={() => {
                    setTabActive(key);
                  }}
                  onKeyDown={() => {}}
                  className={classNames(
                    "flex-1 tab tab-bordered font-medium hover:text-primary-700",
                    tabActive === key
                      ? "text-primary-700 bg-primary-50 border-b-2 border-b-primary-700"
                      : "border-b"
                  )}
                >
                  {tab.label}
                </span>
              ))}
            </div>

            <Table
              bordered
              stripped
              layout="auto"
              className="mb-4"
              columns={renderColumn()}
              dataSource={renderDataSource()}
              pagination={renderPaginator()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Adendum;
