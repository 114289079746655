import { addOIDCUser, removeOIDCUser } from 'stores/reducers/oidcSlice';
import store from 'stores';

class UserStore {
  constructor({ prefix = "oidc.", store = null } = {}) {
    this._store = store;
    this._prefix = prefix;
  }

  set(key, value) {
    key = this._prefix + key;

    const userData = JSON.parse(value);
    store.dispatch(addOIDCUser(userData));

    return Promise.resolve();
  }

  get(key) {
    key = this._prefix + key;

    let item = store.getState()?.oidc?.oidc_user ?? {};

    return Promise.resolve(JSON.stringify(item));
  }

  remove(key) {
    key = this._prefix + key;

    let item = store.getState()?.oidc?.oidc_user;
    store.dispatch(removeOIDCUser());

    return Promise.resolve(item);
  }

  getAllKeys() {
    var keys = [];

    for (let index = 0; index < this._store.length; index++) {
      let key = this._store.key(index);

      if (key.indexOf(this._prefix) === 0) {
        keys.push(key.substr(this._prefix.length));
      }
    }

    return Promise.resolve(keys);
  }
}

export const USER_MANAGER_CONFIG = {
    authority: process.env.REACT_APP_OIDC_IDENTITY_SERVER_URI,
    client_id: "web_app_internal",
    redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URI,
    // post_logout_redirect_uri: process.env.REACT_APP_SIGNOUT_REDIRECT_URL,
    // silent_redirect_uri: process.env.REACT_APP_SILENT_REDIRECT_URL,
    response_type: "code",
    scope: "openid profile email mis.api",
    response_mode: "query",
    monitorSession: false,
    userStore: new UserStore(),
    metadata: {
      issuer: `${process.env.REACT_APP_OIDC_IDENTITY_SERVER_URI}`,
      jwks_uri: `${process.env.REACT_APP_OIDC_IDENTITY_SERVER_URI}/.well-known/openid-configuration/jwks`,
      authorization_endpoint: `${process.env.REACT_APP_OIDC_IDENTITY_SERVER_URI}/connect/authorize`,
      token_endpoint: `${process.env.REACT_APP_OIDC_IDENTITY_SERVER_URI}/connect/token`,
      userinfo_endpoint: `${process.env.REACT_APP_OIDC_IDENTITY_SERVER_URI}/connect/userinfo`,
      end_session_endpoint: `${process.env.REACT_APP_OIDC_IDENTITY_SERVER_URI}/connect/endsession`,
      check_session_iframe: `${process.env.REACT_APP_OIDC_IDENTITY_SERVER_URI}/connect/checksession`,
      revocation_endpoint: `${process.env.REACT_APP_OIDC_IDENTITY_SERVER_URI}/connect/revocation`,
      introspection_endpoint: `${process.env.REACT_APP_OIDC_IDENTITY_SERVER_URI}/connect/introspect`,
    },
};