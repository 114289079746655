import {
  Breadcrumbs,
  Card,
  CardBody,
  CardHeader,
  CustomSelect,
  TitleText,
} from "components";
import { useMemo } from "react";
import CustomCharts from "./components/custom-chart";

const links = [{ label: "Dashboard Perjanjian" }];

const getMonths = ({ count = 12 } = {}) => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return Array.from({ length: count }, (_, i) => monthNames[i % 12]);
};

const Dashboard = () => {
  const labels = useMemo(() => getMonths({ count: 12 }), []);

  const data = useMemo(
    () => ({
      labels,
      datasets: [
        {
          label: "Sedang Dikerjakan",
          data: [1000, 800, 750, 600, 550, 500, 475, 450, 400, 500, 520, 600],
          backgroundColor: "#FF8B49",
          stack: "Stack 0",
          borderRadius: 10,
        },
        {
          label: "Selesai",
          data: [1000, 800, 750, 600, 550, 500, 475, 450, 400, 500, 520, 600],
          backgroundColor: "#27AE60",
          stack: "Stack 1",
          borderRadius: 10,
        },
      ],
    }),
    [labels]
  );

  const listYear = useMemo(() => {
    let currentYear = new Date().getFullYear();
    let years = Array.from({ length: currentYear - 1999 }, (_, i) => ({
      value: currentYear - i,
      label: currentYear - i,
    }));
    return years;
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <Breadcrumbs items={links} />
      <div className="flex justify-between">
        <TitleText className="flex-1">Daftar Adendum/Perjanjian</TitleText>
        <CustomSelect
          className="w-[205px]"
          placeholder="Tahun"
          optionsData={listYear}
          isClearable
        />
      </div>
      <Card>
        <CardHeader>Penyaluran Langsung</CardHeader>
        <CardBody className="flex flex-col gap-12">
          <div>
            <TitleText className="flex-1">Perorangan Dalam Kelompok</TitleText>
            <hr className="my-4" />
            <CustomCharts data={data} />
          </div>

          <div>
            <TitleText className="flex-1">Penyaluran Non Perorangan</TitleText>
            <hr className="my-4" />
            <CustomCharts data={data} />
          </div>
        </CardBody>
      </Card>

      <Card>
        <CardHeader>Penyaluran Tidak Langsung</CardHeader>
        <CardBody>
          <TitleText className="flex-1">Lembaga Penyalur</TitleText>
          <hr className="my-4" />
          <CustomCharts data={data} />
        </CardBody>
      </Card>

      <Card>
        <CardHeader>Penghimpunan & Pengadaan Dana</CardHeader>
        <CardBody>
          <CustomCharts data={data} />
        </CardBody>
      </Card>

      <Card>
        <CardHeader>Penyaluran Dana Program</CardHeader>
        <CardBody>
          <CustomCharts data={data} />
        </CardBody>
      </Card>
    </div>
  );
};

export default Dashboard;
