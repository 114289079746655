import { AnimatePresence } from "framer-motion";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { SnackbarProvider } from "notistack";
import React from "react";
import ReactDOM from "react-dom/client";
import localization from 'moment/locale/id';
import moment from 'moment';
import Modal from 'react-modal';

import { AuthProvider } from "providers/authProvider";

import "./index.css";

import reportWebVitals from "./reportWebVitals";
import Routes from "./routes";
import store, { persistor } from "stores";
import { PersistGate } from "redux-persist/integration/react";

import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    }
  }
});

moment.updateLocale('id', localization);
Modal.setAppElement('#root');

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      {/* redux store */}
      <Provider store={store}>
        {/* redux store persisted */}
        <PersistGate loading={null} persistor={persistor}>
          <AuthProvider>
            <SnackbarProvider
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              maxSnack={3}
              disableWindowBlurListener
              autoHideDuration={3000}
            >
              <AnimatePresence mode="wait">
                <Routes />
              </AnimatePresence>
            </SnackbarProvider>
          </AuthProvider>
        </PersistGate>
      </Provider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
