import classNames from "classnames";
import { Bar } from "react-chartjs-2";

const options = {
  plugins: {
    canvasBackgroundColorPlugin: {
      color: "#F9FAFB",
    },
    legend: {
      display: false,
      // position: "bottom",
      // labels: {
      //   usePointStyle: true,
      //   pointStyle: "round",
      //   boxWidth: 24,
      //   font: {
      //     size: 12,
      //   },
      // },
    },
    title: { display: true, text: "Perorangan dalam kelompok" },
  },
  responsive: true,
  interaction: { mode: "index", intersect: false },
  scales: {
    x: {
      stacked: false,
      grid: { display: false },
    },
    y: { stacked: false },
  },
};

const CustomCharts = ({ data }) => {
  console.log(data, "DATA");

  return (
    <div className="flex flex-col gap-5">
      <div className="p-4 bg-[#F9FAFB] rounded-xl">
        <Bar options={options} data={data} />
      </div>
      <div className="flex gap-4 text-sm justify-center">
        {data.datasets.map((data) => (
          <div className="flex gap-2 items-center text-xs font-medium">
            <div className={`h-3 w-3 rounded-full bg-[${data.backgroundColor}]`}></div>
            <p>{data.label}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomCharts;
